import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

// Libraries]
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import SwiperCore, { Pagination } from 'swiper'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ParseContent from 'components/shared/ParseContent'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'
import Grid from './Layout/Grid'

SwiperCore.use([Pagination])

interface HighlightedProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Highlighted: React.FC<HighlightedProps> = ({ fields }) => {
  const {
    allWpPost,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedQueryQuery>(graphql`
    query highlightedQuery {
      allWpPost {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges
  const postIds = fields.posts?.map((post) => post?.databaseId)

  return (
    <section>
      <BlogBink
        showIds={postIds as Array<number>}
        posts={posts as BlogBinkPosts}
        id="highlighted"
        limit={Infinity}
      >
        <div className="py-5">
          <Grid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

export default Highlighted
